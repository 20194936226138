<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 200px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Archives</span>
        </div>
      </div>
      <!--  -->
      <div class="z-10">
        <div class="planning-content p-8 pb-8">
          <div class="flex flex-col sm:flex-row sm:items-center gap-4">
            <div class="flex items-center space-x-2 overflow-x-auto">
              <div @click="skipPath" class="flex items-center space-x-1.5 cursor-pointer">
                <IconFolder class="h-5 w-5" />
                <span class="text-sm text-hgray10">Root</span>
              </div>

              <span v-if="paths.length" class="text-sm text-hgray10">></span>

              <div
                @click="skipPath(val)"
                v-for="(val, idx) in paths"
                :key="idx"
                class="flex items-center cursor-pointer"
              >
                <IconFolder class="h-5 w-5 mr-1.5" />
                <span class="text-sm text-hgray10">{{ val.name }}</span>

                <span v-if="idx != paths.length - 1" class="text-sm text-hgray10 ml-2">></span>
              </div>
            </div>
          </div>

          <div class="mt-5 flex flex-col space-y-4 w-full">
            <div class="flex flex-col items-center" v-if="!archives.length">
              <img :src="NoFolder" class="w-28 h-18" />
              <div class="text-sm mt-1 text-hgray10">Data masih kosong</div>
            </div>
            <div v-for="(val, idx) in archives" :key="idx" class="rounded-lg shadow">
              <div
                @click="toPath(val)"
                class="flex space-x-2.5 cursor-pointer px-3 py-3"
                v-if="val.type == 'group'"
              >
                <IconFolders class="h-8 w-8" />
                <div class="flex flex-col w-full">
                  <span class="text-hgray10 text-sm font-medium">
                    {{ val.name }}
                  </span>
                  <span class="text-xs text-hgray5">{{ val.created_at }}</span>
                </div>
              </div>

              <div class="flex space-x-2.5 px-3 py-3" v-else>
                <IconFile class="h-8 w-8" />
                <div class="flex flex-col w-full">
                  <span class="text-hgray10 text-sm font-medium">
                    {{ val.name }}
                  </span>
                  <span class="text-xs text-hgray5">{{ val.created_at }}</span>
                </div>

                <button
                  @click="toPath(val)"
                  class="px-2 py-1 rounded-lg border border-hblue6"
                  v-if="val.type != 'group'"
                >
                  <div v-if="!downloading" class="text-sm font-medium">Download</div>

                  <Loader v-else></Loader>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="uri" class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30">
      <div @click="closeIframe" class="absolute w-full h-full bg-gray-900 opacity-50"></div>

      <div class="h-full w-full relative rounded-lg inset-x-0 translate-x-1/2 max-w-3xl">
        <iframe ref="iya" class="absolute w-full h-full"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import IconFolder from '@/assets/icons/icon-folder.svg?inline'
import IconFolders from '@/assets/icons/icon-folders.svg?inline'
import IconFile from '@/assets/icons/icon-file.svg?inline'

import NoFolder from '@/assets/images/no-folder.png'

import api from '@/services/api'

import Loader from '@/components/Loader'

import { mapActions } from 'vuex'

export default {
  name: 'archive',
  components: {
    IconArrow,
    IconFolder,
    IconFile,
    IconFolders,
    Loader
  },
  data() {
    return {
      NoFolder,
      meta: {},
      archives: [],
      paths: [],
      uri: null,
      rootId: null,
      downloading: false
    }
  },
  watch: {
    '$route.query': {
      deep: true,
      handler() {
        this.getData()
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions('auth', ['GET_ARCHIVES', 'DOWNLOAD_ARCHIVE']),

    async getData() {
      let queries = JSON.parse(JSON.stringify(this.$route.query))

      let res = await this.GET_ARCHIVES({ root_id: queries.uri, limit: 200 })

      if (res) {
        this.archives = res.data
        this.meta = res.meta
        this.paths = res.meta.root_path.paths
      }
    },
    async toPath(val) {
      if (val.type == 'group') {
        let queries = JSON.parse(JSON.stringify(this.$route.query))
        queries.t = new Date().getTime()
        queries.uri = val.id

        this.$router.push({ query: queries })
      } else {
        this.downloading = true
        await this.DOWNLOAD_ARCHIVE(val)

        setTimeout(() => {
          this.downloading = false
        }, 300)

        // this.rootId = val.id
        // this.uri = `${process.env.VUE_APP_BASE_URL}/api_v2/customer/archives/${val.id}/download`

        // this.getDownload(val)
        // console.log(href)
        // window.open(href, '_blank')
      }
    },
    skipPath(val = null) {
      let queries = JSON.parse(JSON.stringify(this.$route.query))
      queries.t = new Date().getTime()

      if (val) queries.uri = val.id
      else queries.uri ? delete queries.uri : null

      this.$router.push({ query: queries })
    },
    async getDownload(val) {
      this.downloading = true
      const res = await api.get(`/api_v2/customer/archives/${val.id}/download`, {
        responseType: 'blob',
        headers: {
          'Content-Type': val.mimes,
          Accept: val.mimes
        }
      })

      const blob = new Blob([res.data], { type: val.mimes })
      const urlObject = URL.createObjectURL(blob)

      this.$refs.iya.setAttribute('src', urlObject)

      setTimeout(() => {
        URL.revokeObjectURL(urlObject)
      }, 100)

      this.downloading = false
    },
    closeIframe() {
      this.uri = null
    }
  }
}
</script>
